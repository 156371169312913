 const base = "/";
// const baseUrl = "http://localhost:29553/api/";
const baseUrl = "https://candourapi.sysmedac.com/api/";
//const baseUrl="http://localhost:29553/api/";
const templateDownloadURL="https://candourapi.sysmedac.com/Uploads/Templatefiles/"
const zoopApiKey="NTD3196-C5WM8X6-P62Q2AZ-RGSZAWR"
const zoopAppID="6486cc107a8321001c1b6ebf"

export const routerPath = {
  preAndPostTemplateURL:templateDownloadURL,
  home: base,
  dashboard: base + "dashboard",
  zoop:{
    zoopApiKey:zoopApiKey,
    zoopAppID:zoopAppID
  },
  auth: {
    home: base,
    memberForgotPassword: base + "auth/forgot-password",
    logout: base + "auth/logout",
    updatenewpassword: base + "auth/update-password",
  },

  case: {
    home: base + "case",
    preCase: base + "case/pre-case",
    preCaseCustomerdetails: base + "case/pre-customerdetails",
    preCaseOfficedetails: base + "case/pre-officedetails",
    preCaseOtherdetails: base + "case/pre-otherdetails",
    preCaseCoApplicant: base + "case/co-applicant",
    preCaseGuarantor: base + "case/pre-guarantor",
    postCase: base + "case/post-case",
    postCaseCustomerdetails: base + "case/post-customerdetails",
    postCaseOtherdetails: base + "case/post-otherdetails",
    backofficetray: base + "case/backofficetray",
    postbackofficetray: base + "case/postbackofficetray",
    postcasedocument: base + "case/postcasedocument",
    postimages: base + "case/postimages",
    qctray: base + "case/qctray",
    postdocumentqctray: base + "case/postdocumentqctray",
    postbackofficeqctray: base + "case/postbackofficeqctray",
    postimageqctray: base + "case/postimageqctray",
    misreport: base + "case/misreport",
  },
  backoffice: {
    home: base + "backoffice",
    postcase: base + "backoffice/postcase",
    precase: base + "backoffice/precase",
    kycDetails: base + "backoffice/kyc-details"
  },
  qcTray: {
    home: base + "qctray",
    postcase: base + "qctray/postcase",
    precase: base + "qctray/precase",

  },
  misreport: {
    home: base + "misreport",
    postcase: base + "misreport/postcase",
    precase: base + "misreport/precase",

  },

  accounts: {
    home: base + "accounts",
    invoice: base + "accounts/invoice",
    payroll: base + "accounts/payroll",
    commissionPayout: base + "accounts/commission-payout",
  },
  staff: {
    home: base + "staff",
    staffDetails: base + "staff/details",
    mapping: base + "staff/mapping",
  },
  config: {
    home: base + "config",
    onboard: base + "config/onboard",
    vendor: base + "config/vendor",
    branch: base + "config/branch",
    banks: base + "config/banks",
    template: base + "config/templates",
    agency: base + "config/agency",
    productmaster: base + "config/productmaster",
    productdetails: base + "config/productdetails",
    tatmatrix: base + "config/tatmatrix",
    querymaster: base + "config/querymaster",
    querytype: base + "config/querytype",
    hubmaster: base + "config/hubmaster",
    usermaster: base + "config/usermaster",
    accessmanagement: base + "config/accessmanagement",
    routeassignment: base + "config/routeassignment",
    segmentmaster: base + "config/segmentmaster",
    verificationmaster: base + "config/verificationmaster",
    holidayMaster: base + "config/holidaymaster",
    routeMaster: base + "config/RouteMaster",
    caseReassign:base + "config/CaseReassign"
  },
  
  sampler:{
    application:base + "application",
    applicationform:base + "applicationform",
  },

  API: {
    userLogin: baseUrl + "admin/get-user-login",
    forgetPassword: baseUrl + "admin/forgot-password",
    updatePassword: baseUrl + "admin/forgot-password-updation",

    //GetStaff

    getAllStaffDetails: baseUrl + "admin/getallFiledStaff",
    
    config: {
      getDesignationList: baseUrl + "codesignation/getdesignationlist",
      //productMaster
      inserProductmaster      : baseUrl + "config/insert_productmaster",
      updateProductmaster     : baseUrl + "config/update_productmasterdetails",
      searchProductMaster     : baseUrl + "config/search_productmasterdetails",
      pdfProductMaster        : baseUrl + "config/pdfcreatorforproductmaster",
      excelProductmaster      : baseUrl + "config/download-Excel-FileforProductmaster",
      productmaster           : baseUrl + "config/bind_productmasterdetails",
      productmasterForGrid    : baseUrl + "config/bind_productmasterdetails_grid",
      //VendorPage
      insertUpdateVendor: baseUrl + "vendor/insert_vendor",
      getVendor: baseUrl + "vendor/get_vendor",
      getVendorForGrid: baseUrl + "vendor/get_vendor_Grid",
      deleteVendor: baseUrl + "vendor/delete_vendor",
      searchVendor: baseUrl + "vendor/searchVendor",
      vendorPDF: baseUrl + "vendor/pdfcreator",

      //Query Master
      getquerylist          : baseUrl + "config/getquerylist",
      insertQueryMaster     : baseUrl + "config/insert_querymaster",
      updateQueryMaster     : baseUrl + "config/update_querymasterdetails",
      getAllQueryMaster     : baseUrl + "config/bind_querymasterdetails",
      searchQueryMaster     : baseUrl + "config/search_querymasterdetails",
      pdfQueryMaster        : baseUrl + "config/pdfcreatorforquerymaster",
      excelQueryMaster      : baseUrl + "config/download-Excel-FileforQuerymaster",

      // Hub Master
      insertHubMaster       : baseUrl + "hubmaster/inserthub",
      searchHub             : baseUrl + "hubmaster/searchhub",
      getAllHub             : baseUrl + "hubmaster/hubbindgrid",
      getAllHubAll          : baseUrl + "hubmaster/hubbindgrid_All",
      updateHubMaster       : baseUrl + "hubmaster/Updatehub",

      //UserMaster
      insertUpdateUserMaster  : baseUrl + "configUserMaster/insertUserMaster",
      getUserMaster           : baseUrl + "configUsermaster/getUserMaster",
      deleteUserMaster        : baseUrl + "configUsermaster/deleteUserMaster",
      searchUserMaster        : baseUrl + "configUsermaster/getSearchUser",
      getUserRole             : baseUrl + "configUserMaster/getUserRole",
      getUserType             : baseUrl + "configUserMaster/getUserType",
      gethubdetails           : baseUrl + "hubmaster/hubbindgrid",
      insertmasterDeatils     : baseUrl + "configUserMaster/insertUserMaster",
      deleteUserImei              : baseUrl + "configUserMaster/deleteImei",

      // branchdetails
      insertBranchDetails   : baseUrl + "configbank/insert-input-Bank-datas",
      updateBranchDetails   : baseUrl + "configbranch/updatebranchdetails",
      getAllBranchs         : baseUrl + "configbank/bind-bankdetails",
      getAllBranchsForGrid  : baseUrl + "configbank/bind_branchdetails_grid",
      searchBranchs         : baseUrl + "configbank/get-search-bank-datas",
      excelBranch           : baseUrl + "configbank/download-Excel-File",
      pdfBranch             : baseUrl + "configbank/pdfcreatorforbank",
      getAllBranchsByBankId : baseUrl + "configbank/bind_bankdetails_by_bankid",

      // bankdetails
      insertBankDetails     : baseUrl + "configbank/insertbankdetails",
      updateBankDetails     : baseUrl + "configbank/updatebankdetails",
      getAllBanks           : baseUrl + "configbank/getallbank",
      getAllBanksForGrid    : baseUrl + "configbank/getAllBankForGrid",
      searchBanks           : baseUrl + "configbank/get-search-bank-datas",
      excelBank             : baseUrl + "configbank/download-Excel-File",
      pdfBank               : baseUrl + "configbank/pdfcreatorforbank",

      //PreCase
      insertPreCase: baseUrl + "precase/inert-input-precase-datas",
      getPreCaseTable: baseUrl + "precase/get-all-precase-datas",
      getPreCaseGridData: baseUrl + "precase/storeprocedure",
      getPreCaseGridSearch: baseUrl + "precase/gridSearch",
      getvendornames: baseUrl + "precase/get-vendornames",
      getvendornamesInput: baseUrl + "precase/get-vendornames-Input",
      getbanknames: baseUrl + "precase/get-banknames",
      getsegmentnames: baseUrl + "precase/get-segmentnames",
      updatePreCase: baseUrl + "precase/update-input-precase-datas",
      searchPreCase: baseUrl + "precase/get-search-precase-datas",
      excelDownload: baseUrl + "precase/download-Excel-File",
      getProductNames: baseUrl + "precase/get-products",
      getVerificationDetails: baseUrl + "precase/get-verificationdetails",
      getAuthorityMasterDetail: baseUrl + "precase/get-authoritydetails",
      getEmployeeTypeDetails: baseUrl + "precase/get-employeeType",
      getHubDetails: baseUrl + "precase/get-hubdetails",
      getStaffName: baseUrl + "precase/get-staffname",
      getEmployeeType: baseUrl + "precase/get-employeetype",
      getAddressType: baseUrl + "precase/get-addressTypes",
      getSamplingDetail: baseUrl + "precase/get-samplingtype",
      getAgencyNames: baseUrl + "precase/get_agencynames",
      insertDocument: baseUrl + "precase/document-upload",
      getStateList: baseUrl + "configbank/getstatelists",
      getFieldExecutiveName: baseUrl + "precase/getfieldexecutivename",
      getEmployeeDocDetails: baseUrl + "config/Getcustomerdocumenttype",

      getDocumentType : baseUrl + 'documenttype/getdocummenttype',
      getCustomerDocType : baseUrl + 'precase/getcustomerdocumenttype',
      editPreCase : baseUrl + 'precase/Editprecase-data-by-id',

      //PostCase

      inserPostCase: baseUrl + "postcase/insertpostcase",
      getLatestPostCaseId: baseUrl + "precase/getlatest-postcase-id",
      getAllPostCase: baseUrl + "Postcase/get-all-postcase-datas",
      updatePostcase: baseUrl + "postcase/update-input-postcase-datas",

      //BackOffice

      getallPrecaseInspectionData:
        baseUrl + "backofficetray/getAllInspectionData",
      getallPostcaseInspectionData:
        baseUrl + "backofficetray/getAllInspectionData",
      getallgetbackofficestatus: baseUrl + "admin/getbackofficestatus",
      deleteDocument: baseUrl + "document/deletedocumenta",
      saveCaseDocument: baseUrl + "casedoc/Insertcasedocument",
      updateInspectionData:baseUrl + "backofficetray/updateInspectionData",
      getallInspectionData:        baseUrl + "backofficetray/editbackofficetray",
      getallBackOfficeData:baseUrl + "backofficetray/getBOstoreprocedure",

      
      //Qc Tray
      getallPrecaseQcInspectionData: baseUrl + "qcTray/getAllInspectionData",
      getallPostcaseQcInspectionData: baseUrl + "qcTray/getAllInspectionData",
      updateQCInspectionData : baseUrl + "qcTray/updateInspectionData",
      getallQcInspectionData: baseUrl + "qctray/getinspectiondatabycaseid",
      getallQcData:baseUrl + "qctray/getQCstoreprocedure",

      //MIS Report
      getAllMisReportData : baseUrl+"mis/getprecasedetails",
      getMisFilterDataforcase : baseUrl+"mis/getfilterforcase",
      getMisFilterDataforExport : baseUrl+"mis/generatepdf",
      
      //MISDownloadforAllTrayXcell
      //getMISforAllTray : baseUrl + "mis/getCaseDetailsforXcell",
      getMISforAllTray :  baseUrl + "mis/getCaseDetailsforXcell",
      //Dashboard

      getDashBoard: baseUrl + "Dashboard/GetDashboarddata",
      getDashBoardOnload: baseUrl + "Dashboard/GetDashboard-data-by-id",

      //HolidayMaster

      insertHolidayMasterDetails: baseUrl + "holidaymaster/insertholiday",
      editHolidayMaster: baseUrl + "holidaymaster/editholiday",
      updateHolidayMasterDetails: baseUrl + "holidaymaster/updateholiday",
      getAllHolidayMaster: baseUrl + "holidaymaster/getholiday",
      searchHolidayMaster: baseUrl + "holidaymaster/searchHoliday",
      excelHoliday: baseUrl + "holidaymaster/download-Excel-File",
      pdfHoliday: baseUrl + "holidaymaster/pdfcreatorforHolidayMaster",

      //RouteMaster
      insertRouteMasterDetails: baseUrl + "ConfigRouteMaster/inserRouteMaster",
      updateRouteMasterDetails: baseUrl + "ConfigRouteMaster/updateRouteMaster",
      getAllRouteMasterDetails: baseUrl + "ConfigRouteMaster/getAllRouteMaster",
      searchRouteMaster: baseUrl + "ConfigRouteMaster/searchRouteMaster",
      excelRouteMaster: baseUrl + "ConfigRouteMaster/download-Excel-File",
      pdfRouteMaster: baseUrl + "ConfigRouteMaster/pdfcreatorforRouteMaster",
      insertRouteMasterDetailsWithFile: baseUrl + "ConfigRouteMaster/BulkUploadforRouteMaster",
      removePincode: baseUrl + "ConfigRouteMaster/deleteRoute",
      routeMasterAssignTo: baseUrl + "ConfigRouteMaster/routeMasterAssignTo",

      //RouteAssign
      insertRouteAssignDetails: baseUrl + "ConfigRouteAssign/inserRouteAssign",
      updateRouteAssignDetails: baseUrl + "ConfigRouteAssign/updateRouteAssign",
      getAllRouteAssignDetails: baseUrl + "ConfigRouteAssign/getAllRouteAssignment",
      searchRouteAssign: baseUrl + "ConfigRouteAssign/searchRouteAssign",
      excelRouteAssign: baseUrl + "ConfigRouteAssign/download-Excel-File",
      pdfRouteAssign: baseUrl + "ConfigRouteAssign/pdfcreatorforRouteAssign",
      getPincodeByRoute : baseUrl + "ConfigRouteAssign/getpincodelistbyroutename",
      getAllRouteAssignDetailsGrid: baseUrl + "ConfigRouteAssign/getAllRouteAssignment_Grid",

      //ProductDetails
      getAllProductDetails      : baseUrl + "config/bind_productdetails",
      insertProductDetails      : baseUrl + "config/insert_productDetail",
      updateProductDetails      : baseUrl + "config/update_productDetail",
      getAllProductDetailsGrig  : baseUrl + "config/bind_productdetails_grid",

      //Tat Matrixs

      getAllTatMatrix: baseUrl + "tatmatrix/getAllTatMatrix",
      insertTatMatrix: baseUrl + "tatmatrix/insert-tatmatrix-datas",
      updateTatMatrix: baseUrl + "tatmatrix/Update-tatmatrix-datas",
      getBankByVendorId: baseUrl + "tatmatrix/get-banksbyVendorID",
      getBranchByBankId: baseUrl + "tatmatrix/get-BranchbyBankId",
      getProductNamebyBankandBranch: baseUrl + "tatmatrix/get-productdetails",
      getProductNamebyBranch: baseUrl + "tatmatrix/get_productdetails_branch",
      getAllTatMatrixForGrid: baseUrl + "tatmatrix/getAllTatMatrix_Grid",
      getTypeByProduct: baseUrl + "config/productypebyid",

      //SegmentMaster Config
      insertSegment: baseUrl + "config/insertSegment",
      updateSegment: baseUrl + "config/updateSegement",
      getAllSegment: baseUrl + "config/bindSegmentData",

      //Staff Details
      getStaffDetails:baseUrl + "staff/getstaffdetails",
      insertStaffDetails: baseUrl + "staff/insertstaff",
      updateStaffDetails:baseUrl + "staff/updatestaff",
      getManagerList : baseUrl + "staff/getmanagerList",

      //BulkUpload
      bulkUploadPreAndPostCase:baseUrl + "admin/bulkupload",
      bulkUploadPostCase:baseUrl + "admin/postCaseBulkUpload",

      //Access Management
      insertAccessManagement: baseUrl + "configaccessmanagement/insertaccess",
      updateAccessManagement: baseUrl + "configaccessmanagement/insertaccess",
      getAccessManagement: baseUrl + "configaccessmanagement/getallaccess",

      //Case Reassign
      getCaseInfoforreassign : baseUrl + "casereassign/getcaseinfo",
      updateCaseAssign: baseUrl + "casereassign/updatecaseassign",
      getfieldstaffbyhubid : baseUrl + "casereassign/getfieldstaff-by-hubid",
      getFieldStaffList : baseUrl + "precase/getfieldstaffbyhubid",

      //GetALLFieldStaff
      getAllFieldStaff : baseUrl + "admin/getallFiledStaff",

      //GenerateMISpdf 
      getPDF : baseUrl + "mis/getPDF",
      getTotalPDF : baseUrl + "mis/getTotalMis",

      
      // Sampler API's
      getSamplerData : baseUrl + "sampler/getsamplerDetails",
      insertSamplerData : baseUrl + "sampler/insertSampler",
      getSamplerDocType : baseUrl + "sampler/getcustomerdocumenttype",
      getDashboardData : baseUrl + "sampler/getDashboardDetails",

      getKycDocType: baseUrl + "sampler/getKyctype",
      getKycPan: baseUrl + "sampler/getpankyc",

    },
  },
};
