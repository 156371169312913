import React    from 'react';
import {Outlet} from "react-router-dom";

import imgBgAuthLeft       from "../../../assets/images/bg-auth-left.png";
import {Card, CardContent} from "@mui/material";
import imgLogo             from "../../../assets/images/logo.png";

function Index() {
    return (<div className="container-fluid d-flex justify-content-center align-items-center vh-100 bg-auth">
        <div className="row w-100 justify-content-center">
            <div className="col-md-8">
                <Card className="rounded-5">
                    <CardContent className="p-0">
                        <div className="row g-0">
                            <div className="col-md-7 d-none d-md-block">
                                <img src={imgBgAuthLeft} alt="" className="img-fluid" />
                            </div>
                            <div className="col-md-5 d-flex align-items-center">
                                <div className="w-100 p-3">
                                    <div className="text-center mb-3"><img src={imgLogo} alt="logo" style={{height:'102px'}}/></div>
                                    <Outlet/>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    </div>);
}

export default Index;
